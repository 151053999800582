angular.module('fingerink')
    .directive('sendPrepareDocument', function () {
        return {
            restrict: 'AEC',
            replace: true,
            templateUrl: 'web/main/send/steps/prepareDocument/sendPrepareDocument.tpl.html',
            scope: {
                sign: '=',
                parent: '='
            },
            controllerAs: 'controller',
            controller: 'sendPrepareDocumentCtrl'
        };
    })
    .controller('sendPrepareDocumentCtrl', function ($scope, fingerinkDocumentImage, $window, $location, $anchorScroll, $translate) {

        var that = this;
        that.sign = $scope.sign;
        that.parent = $scope.parent;
        that.pageImages = fingerinkDocumentImage.getImages(that.sign);
        that.sign.fields = that.sign.fields || [];
        that.borrar = i => that.sign.fields.splice(i, 1);
        //Poner los firmantes preparadets
        if (that.sign.type == 'Fast' || that.sign.type == 'Email' || that.sign.type == 'SelfSigning') {
            that.signers = [that.sign.documentFields];
        } else {
            if (that.sign.sendType == 'MultipleDocument') {
                that.signers = [that.sign.oneDocumentSignerFields, that.sign.documentFields];
            } else {
                that.signers = [...that.sign.signers, that.sign.documentFields];
            }
        }



        that.siguiente = function () {
            if (that.sign.type == 'Fast' || that.sign.type == 'Email' || that.sign.type == 'SelfSigning') {
                that.parent.siguiente();
                that.selectedwidgeet = null;

            } else {
                if (that.signers.filter(s => s.signType != 'DOCUMENT').find(s => that.sign.fields.filter(f => f.signer == s && f.type == 'sign').length == 0)) {
                    swal($translate.instant('Hay firmantes sin campo de firma'), $translate.instant('Inserta al menos un campo de firma a cada firmante para poder continuar'), 'warning');
                } else {
                    that.parent.siguiente();
                    that.selectedwidgeet = null;

                }
            }
        };





        that.anterior = () => {
            if (that.sign.template || that.sign.type == 'Fast' || that.sign.type == 'Email') {
                that.parent.step = 'insertfirmantes';
                $window.scrollTo(0, 0);
            } else {
                that.parent.anterior();
            }
        };




        $scope.onDrop = function (s, k) {
            var position = {};
            var positionParent = s.target.getBoundingClientRect();

            position.width = Math.min(k.draggable[0].offsetWidth / positionParent.width * 100, 100);

            if (k.draggable[0].attributes['ratio'] && k.draggable[0].attributes['ratio'].value) {
                position.height = Math.min(100, k.draggable[0].offsetWidth * k.draggable[0].attributes['ratio'].value / positionParent.height * 100);
            }else{
                position.height = Math.min( 100, k.draggable[0].offsetHeight / positionParent.height * 100);
            }

            position.left = Math.max(0, Math.min(100 - position.width,  (k.offset.left - positionParent.left - $window.pageXOffset) / positionParent.width * 100)) ;
            position.top = Math.max(0, Math.min(100 - position.height,  (k.offset.top - positionParent.top - $window.pageYOffset) / positionParent.height * 100)) ;
                       

            if (k.draggable[0].attributes['type']) {
                var kk = {
                    page: s.target.attributes['pagina'].value,
                    left: position.left,
                    top: position.top,
                    width: position.width,
                    height: position.height,
                    name: k.draggable[0].attributes['name'].value,
                    answer: k.draggable[0].attributes['answer'].value,
                    parameters: {},
                    type: k.draggable[0].attributes['type'].value,
                    signer: that.signers[0]
                };
                that.selectedwidgeet = kk;
                that.sign.fields.push(kk);
            }
        };



        $scope.ctrlDown = false;
        $scope.ctrlKey = 17;
        $scope.vKey = 86;
        $scope.cKey = 67;

        $scope.keyDownFunc = function ($event, page) {
            if ($scope.ctrlDown && ($event.keyCode == $scope.cKey)) {
                if (that.selectedwidgeet) {
                    that.copiedWidget = that.selectedwidgeet;
                }
            } else if ($scope.ctrlDown && ($event.keyCode == $scope.vKey)) {
                if (that.copiedWidget) {
                    var kk = {
                        page: page,
                        left: that.copiedWidget.left + 1,
                        top: that.copiedWidget.top + 1,
                        width: that.copiedWidget.width,
                        height: that.copiedWidget.height,
                        name: that.copiedWidget.name,
                        answer: that.copiedWidget.answer,
                        signer: that.copiedWidget.signer,
                        type: that.copiedWidget.type
                    };
                    that.selectedwidgeet = kk;
                    that.sign.fields.push(kk);
                }
            }
        };

        angular.element($window).bind("keyup", function ($event) {
            if ($event.keyCode == $scope.ctrlKey) {
                $scope.ctrlDown = false;
            }
            $scope.$apply();
        });

        angular.element($window).bind("keydown", function ($event) {
            if ($event.keyCode == $scope.ctrlKey) {
                $scope.ctrlDown = true;
            }
            $scope.$apply();
        });




        that.goTo = page => {
            $location.hash('page' + page);
            $anchorScroll();
        };
    });
